import { Injectable } from '@angular/core';
import { filter, map, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Project } from '@interfaces/project.interface';
import { Person } from '@interfaces/person.interface';
import { Settings } from '@interfaces/settings.interface';
import { Reviews } from '@interfaces/reviews.interface';
import { Vacancies } from '@interfaces/vacancies.interface';
import { ProjectTechnologies } from '@interfaces/technologies.interface';
import { Service } from '@interfaces/service.interface';
import { Industry } from '@interfaces/industry.interface';

@Injectable({
	providedIn: 'root',
})
export class DataService {
	constructor() {}

	public persons: Subject<Person[]> = new ReplaySubject();
	public projects: Subject<Project[]> = new ReplaySubject();
	public settings: Subject<Settings[]> = new ReplaySubject();
	public reviews: Subject<Reviews[]> = new ReplaySubject();
	public vacancies: Subject<Vacancies[]> = new ReplaySubject();
	public technologies:Subject<ProjectTechnologies[]> = new ReplaySubject();
	public services: Subject<Service[]> = new ReplaySubject();
	public industries: Subject<Industry[]> = new ReplaySubject();

	public getFilteredPersons(lang: string, dept: string) {
		return this.persons.pipe(
			map((item: Person[]) =>
				item.filter(
					(item: Person) =>
						item.language.name === lang && item.department.name === dept
				)
			)
		);
	}

	public getFilteredStatistic(name: string): Observable<Settings | null> {
    if(this.settings) {
      return this.settings.pipe(
        filter((res: Settings[]) => !!res),
        map(item => item.find(item => item.key === name)!)
      );
    }
      return of(null);
	}
}
